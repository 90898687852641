import { template as template_fa86be94b15f4b9092cd43f984f49e20 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fa86be94b15f4b9092cd43f984f49e20(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
