import { template as template_22ba199bb3204fd9b19e8a06dcee164f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_22ba199bb3204fd9b19e8a06dcee164f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
