import { template as template_37ea1e8f3f9c44229a15b7cf563a8cfa } from "@ember/template-compiler";
const FKControlMenuContainer = template_37ea1e8f3f9c44229a15b7cf563a8cfa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
