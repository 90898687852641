import { template as template_ab9ebbf5dea34520a8ad48f71e5e644d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ab9ebbf5dea34520a8ad48f71e5e644d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
