import { template as template_e0c0f1ece1a9469895024c8b4d61e467 } from "@ember/template-compiler";
const FKLabel = template_e0c0f1ece1a9469895024c8b4d61e467(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
